/* autoprefixer grid: autoplace */

@import 'mixins';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

$ff: 'Noto Sans', sans-serif;

$primary-color: #391354;
$primary-green: #72a826;
$header-text-color: #ffffff;
$primary-button-color: #391354;
$primary-button-text-color: #ffffff;
$secondary-button-color: #41418a;
$secondary-button-text-color: #ffffff;
$button-disabled-color: #959595;
$link-color: #636363;
$active-link: #23ae49;
$unread-notifications: #d60926;
$transaction-button: #df9e27;
$subheader-text: #e62a77;
$gcu-blue: #00587c;
$text-grey: #6c7073;
$invalid-text: #d60926;

// Screen sizes - breakpoints for media queries
$screen-xxs: 360px;
$screen-xs: 500px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1230px;
$screen-xl: 1460px;
$screen-xx: 2000px;

.wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
  padding-right: 10px;
  padding-left: 10px;

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media (min-width: $screen-xs) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (min-width: $screen-lg) {
    padding-right: 150px;
    padding-left: 150px;
  }
}
