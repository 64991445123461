@mixin paginationButton($bgColor: $secondary-button-color) {
  @include font-size(1.6, 1.6);
  background: none;
  font-family: 'Noto Sans', sans-serif;
  border: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #5a6771;
  transition: color 0.2s ease-in-out;
  padding: 0;
  outline: none;

  a {
    color: $primary-color;
  }

  svg {
    @include font-size(2);
    color: $primary-green;
  }

  &:hover {
    cursor: pointer;
  }

  & + button {
    margin-left: 20px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      span {
        background: $bgColor;
        transform: none;
      }
    }
  }

  @media (min-width: $screen-sm) {
    & + button {
      margin-left: 40px;
    }
  }
}

@mixin linkStyles($lkColor: $link-color, $fSize: 16px) {
  color: $lkColor;
  font-weight: 600;
  font-size: $fSize;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: darken($lkColor, 30%);
  }
}

@mixin font-size($sizeValue: 1.6, $lineHeight: 2.4) {
  font-size: $sizeValue + rem;
  line-height: ($lineHeight * 10) + px;
}

@mixin placeholder($bgColor: $primary-color) {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $bgColor;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $bgColor;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $bgColor;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $bgColor;
  }
}

@mixin buttonStyle($bgColor: $link-color, $textColor: #fff) {
  @include font-size(1.6, 1.6);
  background: $bgColor;
  font-weight: 500;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  color: $textColor;
  border: none;
  border-radius: 5px;
  min-width: 52px;
  padding: 19px 22px;

  &:hover {
    background: lighten($bgColor, 20%);
  }

  &:active {
    background: lighten($bgColor, 30%);
    transition: background 0s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #959595;
  }
}
