@import './css/variables';
@import './css/hamburger';

/* This file should only contain overrides for global and boostrap styles */
/* Any other styles should ideally be contained in a component style sheet */

$ff: 'Noto Sans', sans-serif;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  color: #636363;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  height: 100%;
  width: 100%;
  /* Sets up the Base 10 stuff */
}

body {
  // font-family: futura-pt, sans-serif;
  font-family: $ff;
  // line-height: 1.2;
  // width: 100%;
  // height: 100%;
  // margin: 0;
  // padding: 0;
  // overflow-x: hidden;
  &.no-scroll {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  @media (min-width: $screen-md) {
    &.overlay {
      overflow-y: hidden;

      &:after {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: rgba(0, 0, 0, 0.75);
        top: 0;
        z-index: 1;
      }
    }
  }
}

.progress-bar {
  background: #f7f7f7;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  .dots {
    display: grid;
    height: 40px;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto auto;
    justify-content: center;
    position: relative;
    top: -20px;
    min-width: 320px;
    margin: 0 auto;
    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #fff;
      position: relative;
      justify-self: center;
      align-self: center;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: lighten($primary-color, 70%);
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 19px;
        height: 19px;
        background: $primary-color;
        z-index: 99;
        opacity: 0;
      }
      &.active {
        &:before {
          opacity: 1;
        }
      }
      &.completed {
        &:after {
          background: $primary-color;
        }
      }
      &.animate {
        &:before {
          animation: move-baby 1s forwards;
        }
      }
    }
  }
  @media (min-width: $screen-lg) {
    .dots {
      min-width: 627px;
      margin: 0 auto;
      height: 55px;
      top: -27px;
      span {
        &.animate {
          &:before {
            animation: move-baby-desktop 1s forwards;
          }
        }
      }
    }
  }
}

//Bootstrap style overrides
select {
  @include font-size(1.8);
  font-family: $ff;
}

form {
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
    label,
    .label {
      margin-bottom: 1.5rem;
      font-weight: bold;
    }
    .select-box {
      position: relative;
      svg {
        @include font-size(2.5, 2.8);
        position: absolute;
        left: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        & + select {
          padding-left: 45px;
        }
        &.fa-chevron-down {
          @include font-size(1.8, 1.8);
          right: 1.2rem;
          left: auto;
          color: $link-color;
        }
      }
      select {
        @include font-size(1.8);
        font-family: $ff;
        border-radius: 1rem;
        height: 50px;
        line-height: 41px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
    textarea {
      font-family: $ff;
      padding: 1rem;
      border-radius: 1rem;
    }
  }
}

section {
  padding: 30px 0;
  & > section {
    padding: 0px;
  }
}

h1 {
  @include font-size(3.6, 4.6);
}

h2 {
  @include font-size(2.6, 3);
}

h3 {
  @include font-size(2.3, 2.8);
}

h4 {
  @include font-size(1.7, 2.4);
}

h5 {
  @include font-size(1.6, 2.3);
}

a {
  @include font-size(1.6, 2);
  &:focus {
    outline: none;
  }
}

i {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  &.title {
    font-weight: bold;
    color: $primary-color;
  }
}

label,
.label {
  @include font-size(1.8);
}

p,
ul li,
ol li,
table {
  @include font-size(1.6, 2.4);
  color: #7b8892;
}

.form-control {
  @include font-size(1.6, 3);
  height: auto;
}

p {
  margin: 15px 0;
  color: #9a9a9a;

  &.preamble {
    @include font-size(2.2, 3);
    color: #696969;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ol {
  padding-left: 37px;
}

ul {
  list-style: none;

  li {
    @include font-size(1.6, 2.6);
    display: block;
    margin-top: 10px;
    vertical-align: middle;

    &:before {
      content: '';
      width: 7px;
      height: 7px;
      float: left;
      margin-top: 12px;
      margin-right: 10px;
      margin-left: -17px;
      display: inline-block;
      vertical-align: top;
    }

    ul {
      margin-bottom: 0;
      padding-left: 40px;

      li {
        clear: both;

        ul {
          padding-left: 60px;
        }
      }
    }
  }
}

ol {
  list-style: decimal;

  ol {
    clear: both;
    margin-bottom: 0;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: 600;
}

.go-back {
  @include paginationButton;
  max-width: 100px;
  margin-bottom: 20px;
  text-decoration: none;
  .circle {
    background: $primary-button-color;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      transform: rotate(-180deg);
      width: 1em;
      height: 1em;
      font-size: 1.5rem;
      line-height: 24px;
      path {
        fill: white;
      }
    }
  }
  &:hover {
    opacity: 0.7;
  }
}

button {
  @include font-size(1.6, 1.6);
  color: $primary-button-color;
  background: none;
  border: 0;
  transition: all 0.3s ease-in-out;
  &.slick-arrow {
    box-shadow: 0px 0px 30px -7px rgba(0, 0, 0, 0.75);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  font-family: $ff;
}

@media (min-width: 375px) {
  h1 {
    @include font-size(4, 5.2);
  }
}

@media (min-width: 600px) {
  h1 {
    @include font-size(5.2, 5.8);
  }
}

@media (min-width: $screen-md) {
  h1 {
    @include font-size(6, 6.6);
  }

  h2 {
    @include font-size(2.8, 3.6);
  }

  h3 {
    @include font-size(2.3, 3.1);
  }

  h4 {
    @include font-size(2, 2.8);
  }

  .mobile-only {
    display: none;
  }
}

@media (min-width: $screen-lg) {
  h1 {
    @include font-size(7, 8.5§);
  }

  p {
    margin-bottom: 15px;
    @include font-size(1.8, 2.8);

    &.preamble {
      margin-bottom: 20px;
    }
  }

  ul {
    li {
      @include font-size(1.8, 2.8);
    }
  }

  form {
    .form-group {
      margin-bottom: 3rem;
      label,
      .label {
        margin-bottom: 1.5rem;
        font-weight: bold;
      }
      .select-box {
        position: relative;
        width: 300px;
        svg {
          @include font-size(2.5, 2.8);
          position: absolute;
          left: 1.2rem;
          top: 50%;
          transform: translateY(-50%);
          & + select {
            padding-left: 45px;
          }
          &.fa-chevron-down {
            @include font-size(1.8, 1.8);
            right: 1.2rem;
            left: auto;
            color: $link-color;
          }
        }
        select {
          @include font-size(1.8);
          font-family: $ff !important;
          border-radius: 1rem;
          height: 50px;
          line-height: 41px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          option {
            font-family: $ff !important;
          }
        }
      }
      textarea {
        padding: 1rem;
        border-radius: 1rem;
      }
    }
  }
}

@media (min-width: $screen-xl) {
  h2 {
    @include font-size(3.6, 4.2);
  }

  h3 {
    @include font-size(3, 3.8);
  }

  h4 {
    @include font-size(2.3, 3.1);
  }
}

@media (min-width: 1700px) {
  body {
    @include font-size(1.8, 2.1);
  }

  h1 {
    @include font-size(9, 10);
  }

  h2 {
    @include font-size(4, 5);
  }

  h3 {
    @include font-size(3.2, 4.2);
  }

  h4 {
    @include font-size(2.6, 3);
  }

  h5 {
    @include font-size(2, 2.3);
  }

  p {
    &.preamble {
      @include font-size(2.5, 3.8);
    }
  }

  p,
  ul li,
  ol li,
  table {
    @include font-size(1.8, 2.7);
    a {
      margin-bottom: 0 !important;
    }
  }
}

@media (min-width: 2000px) {
  p {
    @include font-size(2, 3);
  }
  ul {
    @include font-size(2, 3);
  }

  ul {
    li {
      @include font-size(2, 3);
    }
  }

  h2 {
    @include font-size(4.2, 5.2);
  }

  h4 {
    @include font-size(2.8, 3.6);
  }
  form {
    .form-group {
      .select-box {
        select {
          @include font-size(2, 3);
        }
      }
      textarea {
        @include font-size(2, 3);
        padding: 2rem;
      }
    }
  }
}

form {
  .form-control {
    background-color: #f4f6f8;
    border-color: #f4f6f8;
    height: auto;
    @include font-size(1.8);
  }
}

.btn {
  @include buttonStyle($primary-button-color);
  width: 100%;
  font-family: $ff;

  &.btn-primary {
    background-color: $primary-button-color;
    border-color: $primary-button-color;
    color: $primary-button-text-color;
  }

  &.btn-secondary {
    background-color: $secondary-button-color;
    border-color: $secondary-button-color;
    color: $secondary-button-text-color;
  }

  @media (min-width: $screen-xs) {
    width: auto;
  }
}

.container {
  margin: 0 auto;
  max-width: 1200px;

  @media (min-width: $screen-xl) {
    max-width: 1400px;
  }
}

.table {
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f5f7;
  }

  th,
  td {
    border: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.contact-cu {
  padding: 15px 0;
  background: $primary-color;
  position: absolute;
  bottom: -20%;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
  z-index: 99;
  left: 0;
  p {
    margin: 0;
    color: #fff;
  }
  &.true {
    bottom: 0;
  }

  @media (min-width: $screen-md) {
    padding: 30px 0;
  }
}

.pagination {
  width: 100%;
  margin: 20px 0;
  align-items: center;
  position: relative;
  display: flex;

  .prevButton {
    @include paginationButton;
  }

  .nextButton {
    @include paginationButton;
  }

  p {
    margin: 0 auto;
    color: #5a6771;
  }

  button {
    &.prevButton {
      span {
        margin-left: 10px;
      }
    }
    &.nextButton {
      display: flex;
      span {
        margin-right: 10px;
      }
    }
  }
}

.sk-fading-circle {
  width: 30px;
  height: 30px;
  position: relative;

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 3px;
      height: 3px;
      background-color: white;
      border-radius: 100%;
      -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }
  }
  .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
    &:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
  }
  .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
    &:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
  }
  .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    &:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }
  }
  .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
    &:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
  .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
    &:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
  }
  .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    &:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
  }
  .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
    &:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
  }
  .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
    &:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
  }
  .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    &:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }
  .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
    &:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
  }
  .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
    &:before {
      animation-delay: -0.1s;
    }
  }
  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}

.error-message {
  text-align: center;
  height: 30px;
  margin-bottom: 40px;
  p {
    @include font-size(1.4);
    color: $invalid-text;
  }
}
